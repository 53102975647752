import React from "react"
import Button from "../../Elements/Button"

export const authFormFields = (values) => {
  return [
    {
      type: "text",
      name: "lastName",
      label: "Patient Last Name",
      helper: `Enter the Last Name you used when you placed your order.`,
      isRequired: true,
      value: values?.otp,
      isFollowUpQuestion: false,
      placeholder: "Dela Cruz Jr.",
      inclusions: ["verify-last-name"],
      maxLength: 50,
    },
  ]
}
